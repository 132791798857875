import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useLocation } from 'react-router-dom/cjs/react-router-dom'
import { policyTableType } from '../../../../types/tablesTypes'
import { getAgents } from '../../../shared/actions/sharedActions'
import { insuranceField, insuranceFieldType } from '../../../../types/insuranceField'
import ModalSidebar from '../../../../components/Common/ModalSidebar/ModalSidebar'
import classNames from 'classnames'
import CategoriesSelector from '../../../shared/components/CategoriesSelector/CategoriesSelector'
import CustomerTablesNavbar from '../../../customer/components/CustomerTablesNavbar/CustomerTablesNavbar'
import Loader from '../../../../components/Common/Loader/Loader'
import { getPolicyCardRequest } from '../../../../http/requests/policiesRequests'
import useApiRequest from '../../../../hooks/useApiRequest'
import { hebrew } from '../../../../i18n'
import { formatAsShortDate } from '../../../../utils/formatAs'
import { AccessTime, CalendarMonthOutlined } from '@mui/icons-material'
import { setPoliciesCategoryRequest } from '../../../../http/requests/policyRequests'
import PolicyStatusChip from '../../../../components/Common/PolicyStatusChip/PolicyStatusChip'
import ButtonRounded from '../../../../components/Common/ButtonRounded/ButtonRounded'
import PolicyIrregs from './PolicyIrregs'
import PolicyPayments from './PolicyPayments'
import PolicyActionsHistory from './PolicyActionsHistory'
import PolicyTypesList from './PolicyTypesList'
import NoData from '../../../../components/Common/NoData/NoData'
import { deltaCommissionSplitView } from '../../../shared/reducers/sharedSelectors'
import './PolicyCard.scss'

const PolicyCard = ({ id, closeDetails }) => {
  const { t } = useTranslation('policies')
  const {
    loading: policyLoading,
    data: policy,
    fetch: getPolicy,
    error,
  } = useApiRequest(getPolicyCardRequest, { requestParams: id, logError: true, runOnInit: true })

  const location = useLocation()

  const { categories: allCategories } = useSelector(({ shared }) => shared)
  const categories = allCategories.filter(c => c.fieldId === policy?.fieldId)

  const [showCategoriesSelector, setShowCategoriesSelector] = useState(false)
  const [updateCategoryLoading, setUpdateCategoryLoading] = useState(false)
  const [currentTableType, setCurrentTableType] = useState(policyTableType.payments)
  const dispatch = useDispatch()

  const showCategoriesSelectorHandler = () => {
    setShowCategoriesSelector(true)
  }

  const closeOnCategoriesOverlayClick = e => {
    e.stopPropagation()
    showCategoriesSelector && setShowCategoriesSelector(false)
  }

  const updateCategory =
    (updateAllRelatedPolicies = false) =>
    async categoryId => {
      try {
        setUpdateCategoryLoading(true)
        const {
          data: {
            data: { updatedPolicies },
          },
        } = await setPoliciesCategoryRequest({ policiesIds: [policy.id], categoryId, updateAllRelatedPolicies })
        setUpdateCategoryLoading(false)
        setShowCategoriesSelector(false)
        updatedPolicies.length > 0 && getPolicy({ silentLoading: true })
      } catch (error) {
        console.log('updateCategory Error', error.message)
        setShowCategoriesSelector(false)
        setUpdateCategoryLoading(false)
      }
    }

  useEffect(() => {
    dispatch(getAgents())
  }, [dispatch])

  if (policyLoading || error) {
    return (
      <ModalSidebar closeSidebar={closeDetails} width='1300px' contentLoading={policyLoading}>
        {policyLoading ? (
          <Loader />
        ) : (
          <NoData
            text={
              <>
                <p>{error}</p>
                <br />
                <ButtonRounded onClick={getPolicy}>Try Again</ButtonRounded>
              </>
            }
          />
        )}
      </ModalSidebar>
    )
  }

  return (
    <>
      <ModalSidebar
        closeSidebar={closeDetails}
        width={deltaCommissionSplitView() ? '1600px' : '1300px'}
        contentLoading={policyLoading}
        closeOnClickOutSide
      >
        <div className='policy-sidecard-container'>
          <div className='policy-sidecard-details-container'>
            {policy.clientId && !location.pathname.includes('customers') && (
              <NavLink exact to={`/customers/${policy.clientId}`}>
                <div className='policy-sidecard-customer-link'>
                  <div className='policy-sidecard-customer-link-text'>{t('policyCard.goToCustomer')}</div>
                  <div className='policy-sidecard-customer-link-icon'>
                    <img src='./assets/arrow-right-common/Right.png' alt='back' className='img-icon' />
                  </div>
                </div>
              </NavLink>
            )}
            <div className='policy-sidecard-details-heading'>
              <h3>{policy.policyNumber}</h3>
              <div>
                <PolicyStatusChip status={policy.status} />
              </div>
            </div>
            <div className='policy-sidecard-details'>
              <div>
                {t('policyCard.customerName')} ({t('policyCard.customerIdNumber')})
              </div>
              <div>
                <span>{policy.clientName ?? '--'}</span>
                {policy.clientIdNumber && (
                  <>
                    {' '}
                    <span className='id-number'>({policy.clientIdNumber})</span>
                  </>
                )}
              </div>
            </div>
            <div className='policy-sidecard-details'>
              <div>{t('policyCard.policyStartDate')}</div>
              <div>
                {policy.startDate ? (
                  <>
                    <CalendarMonthOutlined />
                    <span>{formatAsShortDate(policy.startDate, '.', { fullYear: true })}</span>
                  </>
                ) : (
                  '--'
                )}
              </div>
            </div>
            <div className='policy-sidecard-details'>
              <div>{t('policyCard.policyEndDate')}</div>
              <div>
                {policy.endDate ? (
                  <>
                    <CalendarMonthOutlined />
                    <span>{formatAsShortDate(policy.endDate, '.', { fullYear: true })}</span>
                  </>
                ) : (
                  '--'
                )}
              </div>
            </div>
            <div className='policy-sidecard-details'>
              <div>{t('policyCard.policyAge')}</div>
              <div>
                {policy.policyAge ? (
                  <>
                    <AccessTime />
                    <span>{policy.policyAge ?? '--'}</span>
                  </>
                ) : (
                  '--'
                )}
              </div>
            </div>
            <div className='policy-sidecard-details'>
              <div>{t('policyCard.category')}</div>
              <div
                className={classNames('categories-selector', {
                  selectable: categories.length > 0,
                  opened: showCategoriesSelector,
                })}
                {...(categories.length > 0 && {
                  onClick: showCategoriesSelectorHandler,
                })}
              >
                <div className='categories-selector-item'>{hebrew() ? policy.categoryName : policy.categoryNameEn}</div>
                {categories.length > 0 && (
                  <div className='categories-selector-icon'>
                    <img src='./assets/dropdown-icon-light/dropdown-icon-light.png' alt='opn' />
                  </div>
                )}
              </div>
              {showCategoriesSelector && (
                <>
                  <div className='categories-selector-overlay' onClick={closeOnCategoriesOverlayClick}></div>
                  <CategoriesSelector
                    showSelector={showCategoriesSelector}
                    closeSelector={() => setShowCategoriesSelector(false)}
                    loading={updateCategoryLoading}
                    categories={categories}
                    categoryId={policy.categoryId}
                    updateAllRelatedPoliciesCategory={updateCategory(true)}
                    updatePolicyCategory={updateCategory(false)}
                  />
                </>
              )}
            </div>
            <div className='policy-sidecard-details'>
              <div>{t('policyCard.company')}</div>
              <div>{hebrew() ? policy.companyName : policy.companyNameEn}</div>
            </div>
            <div className='policy-sidecard-details'>
              <div>{t('policyCard.field')}</div>
              <div>
                {(hebrew() ? insuranceField[policy.fieldId]?.name : insuranceField[policy.fieldId]?.nameEn) ?? '--'}
              </div>
            </div>
            {policy.agents.length > 0 && (
              <div className='policy-sidecard-details agents-list'>
                <div>
                  {t('policyCard.agent')} ({t('policyCard.idNumber')})
                </div>
                <div>
                  {policy.agents.map((a, i) => (
                    <div key={a.agentName + a.agentNumber + i}>
                      <span>{a.agentName ?? '--'}</span> <span className='id-number'>({a.agentNumber ?? '--'})</span>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {policy?.agencies?.length > 0 && (
              <div className='policy-sidecard-details agents-list'>
                <div>
                  {t('policyCard.agency')} ({t('policyCard.idNumber')})
                </div>
                <div>
                  {policy.agencies.map((a, i) => (
                    <div key={a.agencyName + a.agencyNumber + i}>
                      <span>{a.agencyName ?? '--'}</span> <span className='id-number'>({a.agencyNumber ?? '--'})</span>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
          <CustomerTablesNavbar
            navbarItems={Object.values(policyTableType).map(ptt => ({
              text: t(`policyCard.${ptt}TabName`),
              type: ptt,
            }))}
            currentTableType={currentTableType}
            setCurrentTable={({ type }) => setCurrentTableType(type)}
          />
          <div className='policy-sidecard-list-container'>
            {currentTableType === policyTableType.payments && (
              <>
                {policy.irregularities.length > 0 && (
                  <div>
                    <p className='policy-sidecard-list-name'>
                      {t('policyCard.signals')} <span>({policy.irregularities.length})</span>
                    </p>
                    <PolicyIrregs
                      irregs={policy.irregularities}
                      policy={policy}
                      onIrregularityAction={() => getPolicy({ silentLoading: true })}
                    />
                  </div>
                )}
                {policy.payments.length > 0 && (
                  <div>
                    <p className='policy-sidecard-list-name'>
                      {t('policyCard.paymentDetails')} <span>({policy.payments.length})</span>
                    </p>
                    <PolicyPayments payments={policy.payments} policyId={policy.id} fieldId={policy.fieldId} />
                  </div>
                )}
              </>
            )}
            {currentTableType === policyTableType.history && (
              <PolicyActionsHistory
                actions={policy.policyActions}
                onCancelAction={() => getPolicy({ silentLoading: true })}
              />
            )}
            {currentTableType === policyTableType.policyTypes && (
              <PolicyTypesList policyTypes={policy.listCoverages} loading={policyLoading} />
            )}
          </div>
        </div>
      </ModalSidebar>
    </>
  )
}

export default PolicyCard
