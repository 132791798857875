import { useEffect, useRef, useState } from 'react'
import { CheckBoxOutlineBlankRounded, CheckBoxRounded, MenuRounded } from '@mui/icons-material'
import RelativeFixedPosition from '../Common/RelativeFixedPosition/RelativeFixedPosition'
import { isDeltaAgency } from '../../features/login/reducers/loginSelectors'
import { useDispatch, useSelector } from 'react-redux'
import { updateColumnsConfig } from '../../features/shared/actions/sharedActions'
import { getColumnName } from '../../services/columnsConfig/columnsConfigHelpers'
import { cn } from '../../utils/stylesUtils'
import store from '../../app/store'
import { deltaCommissionViewType } from '../../types/deltaCommissionViewTypes'
import { familyGroupMode } from '../../features/shared/reducers/sharedSelectors'
import { columnsConfigPage } from '../../services/columnsConfig/columnsConfigTypes'
import { lowercaseFirstLetter } from '../../utils/charsUtils'
import './ColumnsPicker.scss'
import { hebrew } from '../../i18n'

const filterDeltaColumns = columns => {
  const { commissionView } = store.getState().shared
  if (!isDeltaAgency() || commissionView === deltaCommissionViewType.agent) {
    return columns.filter(c => {
      if (
        c.columnName.includes('Commission') &&
        (c.columnName.includes('Agency') || c.columnName.includes('BothTotal'))
      ) {
        return false
      } else {
        return true
      }
    })
  }
  if (commissionView === deltaCommissionViewType.agency) {
    return columns.filter(c => {
      if (c.columnName.includes('Commission') && !c.columnName.includes('Agency')) {
        return false
      } else {
        return true
      }
    })
  }
  if (commissionView === deltaCommissionViewType.bothTogether) {
    return columns.filter(c => {
      if (c.columnName.includes('Commission') && !c.columnName.includes('BothTotal')) {
        return false
      } else {
        return true
      }
    })
  }
  if (commissionView === deltaCommissionViewType.bothSideBySide) {
    return columns.filter(c => {
      if (c.columnName.includes('Commission') && c.columnName.includes('BothTotal')) {
        return false
      } else {
        return true
      }
    })
  }
}

const filterFamilyColumns = ({ columnName, page }) => {
  page = lowercaseFirstLetter(page)
  if (
    !familyGroupMode() &&
    (page === columnsConfigPage.customerElementary ||
      page === columnsConfigPage.customerLife ||
      page === columnsConfigPage.customerAbroad)
  ) {
    if (columnName.includes('CustomerIdNumber') || columnName.includes('CustomerName')) {
      return false
    }
  }
  return true
}

const ColumnsPicker = ({ listKey, t, translationKey }) => {
  const { columnsConfig } = useSelector(({ shared }) => shared)
  const dispatch = useDispatch()
  const [showList, setShowList] = useState(false)
  const [updatedList, setUpdatedlist] = useState(null)

  const btnRef = useRef(null)

  const handleClick = () => {
    if (showList) {
      closeListHandler()
    } else {
      setUpdatedlist(null)
      setShowList(true)
    }
  }

  const closeListHandler = () => {
    if (updatedList) {
      dispatch(updateColumnsConfig(listKey, updatedList))
    }
    setShowList(false)
  }

  if (!columnsConfig[listKey] || columnsConfig[listKey].length === 0) {
    return null
  }

  return (
    <div className='columns-picker-container'>
      <button
        onClick={handleClick}
        ref={btnRef}
        className={cn({ 'has-disabled-columns': columnsConfig[listKey].some(li => !li.visible) })}
      >
        <MenuRounded />
      </button>
      {showList && (
        <RelativeFixedPosition anchorRef={btnRef} onOverlayClick={closeListHandler}>
          <ColumnsPickerList
            list={columnsConfig[listKey]}
            setUpdatedList={setUpdatedlist}
            t={t}
            translationKey={translationKey}
          />
        </RelativeFixedPosition>
      )}
    </div>
  )
}

const ColumnsPickerList = ({ list, setUpdatedList, t, translationKey }) => {
  const [columnsList, setColumnsList] = useState(list)

  const columns = filterDeltaColumns(columnsList).filter(filterFamilyColumns)
  const allSelected = columnsList.every(c => c.visible)

  const selectColumnHandler = id => {
    setColumnsList(prev => prev.map(c => ({ ...c, visible: c.id === id ? !c.visible : c.visible })))
  }

  const selectAllColumnsHandler = () => {
    setColumnsList(prev => prev.map(c => ({ ...c, visible: !allSelected })))
  }

  const selectAllTitle = hebrew() ? 'בחר הכל' : 'Select all'

  useEffect(() => {
    const listIsDirty =
      JSON.stringify(columnsList.filter(c => c.visible).map(({ id }) => id)) !==
      JSON.stringify(list.filter(c => c.visible).map(({ id }) => id))
    setUpdatedList(listIsDirty ? columnsList : null)
  }, [columnsList, list, setUpdatedList])

  return (
    <div className='columns-picker-list'>
      <ul>
        <li onClick={selectAllColumnsHandler} className='select-all'>
          {allSelected ? <CheckBoxRounded /> : <CheckBoxOutlineBlankRounded />}
          <p>{selectAllTitle}</p>
        </li>
        {columns.map(c => (
          <li key={c.id} onClick={() => selectColumnHandler(c.id)}>
            {c.visible ? <CheckBoxRounded /> : <CheckBoxOutlineBlankRounded />}
            <p>{getColumnName(c, t, translationKey)}</p>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default ColumnsPicker
