import {
  POLICY_SELECT,
  POLICY_CHECK,
  POLICY_TREAT_REQUEST,
  POLICY_TREAT_SUCCESS,
  POLICY_TREAT_FAIL,
  POLICY_TREAT_RESET,
  POLICY_UNCHECK_ALL,
  POLICY_RESET,
  CUSTOMER_CHECK,
  CUSTOMER_UNCHECK_ALL,
  POLICY_CHECK_GROUP,
} from '../../../types/actionTypes'

const initialState = {
  selectedPolicy: null,
  checkedPolicies: [],
  checkedCustomers: [],
  irregularities: [],
  irregularitiesLoading: true,
  treatSuccess: false,
  treatLoading: false,
  updateRequest: false,
  policyDetails: null,
  policyDetailsLoading: true,
}

export const policyReducer = (state = initialState, action) => {
  switch (action.type) {
    case POLICY_SELECT:
      return {
        ...state,
        selectedPolicy: action.payload,
        ...(action.payload && { policyDetails: null }),
      }
    case POLICY_CHECK:
      return {
        ...state,
        checkedPolicies: state.checkedPolicies.includes(action.payload)
          ? state.checkedPolicies.filter(p => p !== action.payload)
          : [...state.checkedPolicies, action.payload],
      }
    case POLICY_CHECK_GROUP:
      const policesToCheck = action.payload
      const prevCheckedPolicies = state.checkedPolicies
      // let checkedPolicies = []
      // // all has been already checked, so uncheck them
      // if (policesToCheck.every(p => prevCheckedPolicies.includes(p))) {
      //   checkedPolicies = prevCheckedPolicies.filter(p => !policesToCheck.includes(p))
      // }
      // // check all, that are not checked yet
      // else {
      //   checkedPolicies = [...prevCheckedPolicies, ...policesToCheck.filter(p => !prevCheckedPolicies.includes(p))]
      // }
      return {
        ...state,
        checkedPolicies: policesToCheck.every(p => prevCheckedPolicies.includes(p)) ? [] : policesToCheck,
      }
    case POLICY_UNCHECK_ALL:
      return {
        ...state,
        checkedPolicies: [],
      }
    case CUSTOMER_CHECK:
      return {
        ...state,
        checkedCustomers: state.checkedCustomers.find(c => c.id === action.payload.id)
          ? state.checkedCustomers.filter(c => c.id !== action.payload.id)
          : [...state.checkedCustomers, action.payload],
      }
    case CUSTOMER_UNCHECK_ALL:
      return {
        ...state,
        checkedCustomers: [],
      }
    case POLICY_TREAT_REQUEST:
      return {
        ...state,
        treatLoading: true,
        treatSuccess: false,
      }
    case POLICY_TREAT_SUCCESS:
      return {
        ...state,
        treatLoading: false,
        treatSuccess: action.payload.success,
        irregularities: state.irregularities.map(irr =>
          irr.id === action.payload.irregularityId && action.payload.success
            ? {
                ...irr,
                treatmentInfo: {
                  agentId: action.payload.agentId,
                  agentName: action.payload.agentName,
                  date: new Date(Date.now()),
                },
              }
            : irr
        ),
        checkedPolicies: action.payload.success ? [] : state.checkedPolicies,
        updateRequest: true,
      }
    case POLICY_TREAT_FAIL:
      return {
        ...state,
        treatLoading: false,
        treatSuccess: false,
      }
    case POLICY_TREAT_RESET:
      return {
        ...state,
        treatSuccess: false,
        treatLoading: false,
      }
    case POLICY_RESET:
      return initialState
    default:
      return state
  }
}
