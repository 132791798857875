import { urlPolicy, urlTreatment } from '../../../environment/urls'
import $api from '../../../http'
import {
  POLICY_SELECT,
  POLICY_TREAT_REQUEST,
  POLICY_TREAT_SUCCESS,
  POLICY_TREAT_FAIL,
  CUSTOMER_CHECK,
} from '../../../types/actionTypes'

const treatUrl = urlTreatment()

export const selectPolicy = policyId => {
  return {
    type: POLICY_SELECT,
    payload: policyId,
  }
}

export const checkCustomer = customer => {
  return {
    type: CUSTOMER_CHECK,
    payload: customer,
  }
}

export const sendForTreatBatch = treatDtos => async dispatch => {
  dispatch({ type: POLICY_TREAT_REQUEST })
  treatDtos = treatDtos.map(dto => {
    return {
      agentId: dto?.agentId || null,
      policyId: dto?.policyId || null,
      irregularityId: dto?.irregularityId || null,
      customerId: dto?.customerId || null,
      note: dto?.note?.toString() || null,
      taskId: dto?.taskId || null,
      type: dto?.type || null,
    }
  })
  try {
    const {
      data: {
        data: { success },
      },
    } = await $api.put(`${treatUrl}add-batch`, treatDtos)
    dispatch({
      type: POLICY_TREAT_SUCCESS,
      payload: { success },
    })
  } catch (error) {
    dispatch({
      type: POLICY_TREAT_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}
