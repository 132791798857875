import classNames from 'classnames'
import { useEffect, useRef, useState } from 'react'
import InputSearch from '../InputSearch/InputSearch'
import Loader from '../Loader/Loader'
import CheckMark from '../Marks/CheckMark/CheckMark'
import './ModalWithSelection.scss'

const ModalWithSelection = ({
  items,
  currentItemId,
  closeModal = () => {},
  onClickHandler = () => {},
  loading,
  selectSuccess,
  showSearch = false,
  searchPlaceholder,
}) => {
  const [selectedItem, setSelectedItem] = useState(currentItemId)
  const [filteredItems, setFilteredItems] = useState(items)
  const ref = useRef()
  const selectedRef = useRef()

  const selectItem = itemId => {
    setSelectedItem(items.find(item => item.id === itemId))
    onClickHandler(itemId)
  }

  const filterItems = text => {
    setFilteredItems(items.filter(itm => itm.value.toLowerCase().includes(text.toLowerCase())))
  }

  useEffect(() => {
    if (selectSuccess) {
      closeModal()
    }
  }, [selectSuccess, currentItemId, closeModal])

  useEffect(() => {
    selectedRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
  }, [])

  return (
    <div className='modal-selection' ref={ref}>
      {showSearch && <InputSearch placeholder={searchPlaceholder} searchHandler={filterItems} />}
      <div className='modal-selection-items'>
        {filteredItems.map(itm => (
          <div
            key={itm.id}
            className={classNames('modal-selection-item', { selected: itm.id === selectedItem })}
            {...(itm.id !== selectedItem && {
              onClick: () => selectItem(itm.id),
            })}
            {...(itm.id === selectedItem && { ref: selectedRef })}
          >
            <div>{itm.value}</div>
            {itm.id === selectedItem && (
              <div>
                <CheckMark green={true} />
              </div>
            )}
          </div>
        ))}
      </div>
      {loading && (
        <div className='modal-selection-loader'>
          <Loader />
        </div>
      )}
    </div>
  )
}

export default ModalWithSelection
