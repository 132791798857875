import classNames from 'classnames'
import { useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useSelector } from 'react-redux'
import ExportButton from '../../../../components/Common/ExportButton/ExportButton'
import Loader from '../../../../components/Common/Loader/Loader'
import { hebrew } from '../../../../i18n'
import { agencyUserRoleType } from '../../../../types/agencyUserTypes'
import {
  customerTask,
  customerTaskStatus,
  customerTaskStatusType,
  customerTaskType,
} from '../../../../types/customerTaskTypes'
import { irregularReasonObject } from '../../../../types/irregularReasonTypes'
import { formatAsCurrency, formatAsShortDate } from '../../../../utils/formatAs'
import SetTaskStatusMenu from '../SetTaskStatusMenu/SetTaskStatusMenu'
import TasksDetailsMobile from '../TasksDetailsMobile/TasksDetailsMobile'
import WorkstationTaskAgentMenu from '../WorkstationTaskAgentMenu/WorkstationTaskAgentMenu'
import PolicyCard from '../../../policies/components/PolicyCard/PolicyCard'
import './TasksListItem.scss'

const TasksListItem = ({ task, selectTask = () => {}, selected }) => {
  const {
    categoryEnglishName,
    categoryName,
    date,
    customerName,
    description,
    id,
    irregularReason,
    monetaryValue,
    policyId,
    policyIdNumber,
    responsibleEmployeeName,
    status,
    type,
  } = task
  const [showSetStatusMenu, setShowSetStatusMenu] = useState(false)
  const [showSetAgentMenu, setShowSetAgentMenu] = useState(false)
  const [showTaskDetailsMobile, setShowTaskDetailsMobile] = useState(false)
  const [selectedPolicy, setSelectedPolicy] = useState(null)
  const { role } = useSelector(state => state.login)
  const { taskStatusLoading } = useSelector(state => state.workstation)
  const policyDetails = useSelector(state => state.policy).policyDetails

  const category = hebrew() ? categoryName : categoryEnglishName

  const taskType = hebrew()
    ? customerTask[type].text() +
      (type === customerTaskType.irregularity ? ' - ' + irregularReasonObject[irregularReason]?.name : '')
    : customerTask[type].textEn() +
      (type === customerTaskType.irregularity ? ' - ' + irregularReasonObject[irregularReason]?.nameEn : '')

  const selectTaskHandler = e => {
    e.stopPropagation()
    selectTask(id)
  }

  const canEditTaskAgent = role === agencyUserRoleType.fullUserAccess || role === agencyUserRoleType.agent

  return (
    <>
      <div
        className={classNames('tasks-list-item tasks-list-grid', { clickable: policyId })}
        {...(policyId && {
          onClick: () => {
            setSelectedPolicy(policyId)
          },
        })}
      >
        <div className='task-item-checkbox'>
          <img
            src={`./assets/checkbox-sq/${selected ? 'A' : 'Ina'}ctive.png`}
            alt={selected ? 'unchk' : 'chk'}
            onClick={selectTaskHandler}
          />
        </div>
        <div className='task-item-text-container'>
          {customerName ? <span title={customerName.trim()}>{customerName.trim()}</span> : '-'}
        </div>
        <div className='mobile-hide'>{policyIdNumber ?? '-'}</div>
        <div className='task-item-text-container mobile-hide'>
          <span title={taskType}>{taskType}</span>
        </div>
        <div className='task-item-text-container mobile-hide'>
          <span title={description}>{description ?? ' - '}</span>
        </div>
        <div className='task-item-status mobile-hide'>
          {taskStatusLoading[task.id] && (
            <div className='task-item-status-loader-overlay'>
              <Loader small />
            </div>
          )}
          <button
            className={classNames('task-item-status-chip', {
              treated: status === customerTaskStatusType.treated,
              opened: showSetStatusMenu,
            })}
            onClick={e => {
              e.stopPropagation()
              !showSetStatusMenu && setShowSetStatusMenu(true)
            }}
          >
            <span>{hebrew() ? customerTaskStatus[status].text() : customerTaskStatus[status].textEn()}</span>
            <div className='task-item-status-chip-arrow-ico'>
              <img src='./assets/dropdown-icon-light-sm/dropdown-icon-light-sm.png' alt='opn' />
            </div>
          </button>
          {showSetStatusMenu && (
            <div className='task-item-status-menu-wrapper'>
              <SetTaskStatusMenu closeMenu={() => setShowSetStatusMenu(false)} tasks={[task]} />
            </div>
          )}
        </div>
        <div className='mobile-hide'>{formatAsCurrency(monetaryValue)}</div>
        <div className='mobile-hide'>{category ?? ' - '}</div>
        <div
          className='task-item-agent-container mobile-hide'
          {...(canEditTaskAgent && {
            onClick: e => {
              e.stopPropagation()
              setShowSetAgentMenu(true)
            },
          })}
        >
          <div className={classNames('task-item-agent', { editable: canEditTaskAgent, opened: showSetAgentMenu })}>
            <div className=''>{responsibleEmployeeName}</div>
            {canEditTaskAgent && (
              <div className='task-item-agent-arrow-ico'>
                <img src='./assets/dropdown-icon-light-sm/dropdown-icon-light-sm.png' alt='opn' />
              </div>
            )}
            {showSetAgentMenu && (
              <div className='task-item-agent-menu-wrapper'>
                <WorkstationTaskAgentMenu tasks={[task]} closeMenu={() => setShowSetAgentMenu(false)} />
              </div>
            )}
          </div>
        </div>
        <div className='mobile-hide'>{formatAsShortDate(date)}</div>
        <div className='task-item-extras'>
          <div className='mobile-hide'>
            {Boolean(task.customerId) && <ExportButton link={`/customers/${task.customerId}`} />}
          </div>
          {/* <MeatBallsMenu menuSize={isMobile ? 'sm' : 'md'} /> */}
          <div className='task-item-details-arrow-ico' onClick={() => setShowTaskDetailsMobile(true)}>
            <img src='./assets/dropdown-icon-light/dropdown-icon-light.png' alt='opn' />
          </div>
        </div>
      </div>
      {!isMobile && selectedPolicy && policyDetails && (
        <PolicyCard closeDetails={() => setSelectedPolicy(null)} id={policyDetails.id} />
      )}
      {showTaskDetailsMobile && <TasksDetailsMobile task={task} closeDetails={() => setShowTaskDetailsMobile(false)} />}
    </>
  )
}

export default TasksListItem
