import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import ButtonRounded from '../../../../components/Common/ButtonRounded/ButtonRounded'
import InputText from '../../../../components/Common/InputText/InputText'
import { agencyUserRole } from '../../../../types/agencyUserTypes'
import { hebrew } from '../../../../i18n'
import { formatAsPhoneNumber } from '../../../../utils/formatAs'
import ProfileAvatar from '../ProfileAvatar/ProfileAvatar'
import { updateUserInfoRequest } from '../../../../http/requests/profileRequests'
import './ProfileInfo.scss'
import { getVersion } from '../../../../utils/versionUtils'

const ProfileInfo = () => {
  const {
    details: { fullName, agencyName, userEmail, userPhone },
  } = useSelector(state => state.profile)
  const { role, UserId } = useSelector(state => state.login)
  const roleName = hebrew() ? agencyUserRole[role].name : agencyUserRole[role].nameEn
  const [submitLoading, setSubmitLoading] = useState(false)
  const [serverError, setServerError] = useState(null)
  const [formIsDirty, setFormIsDirty] = useState(false)
  const [agency, setAgency] = useState({ fullName: '', agencyName: '', userEmail: '', userPhone: '' })

  const onChangeHandler = dataType => value => {
    setServerError(null)
    setFormIsDirty(true)
    setAgency(prev => ({
      ...prev,
      [dataType]: value,
    }))
  }

  const formIsValid = () => agency.fullName?.length > 0 && agency.agencyName?.length > 0 && agency.userEmail?.length > 0

  const submitBtnDisabled = () => submitLoading || !formIsValid() || !formIsDirty

  const { t } = useTranslation('profile')

  const inputsDisabled = submitLoading

  const submitHandler = async e => {
    e.preventDefault()
    const emailRegex = new RegExp(/^[A-Za-z0-9_!#$%&'*+=?`{|}~^.-]+@[A-Za-z0-9.-]+$/, 'gm')
    if (!emailRegex.test(agency.userEmail)) {
      setServerError('Invalid email format')
      return
    }
    try {
      setServerError(null)
      setSubmitLoading(true)
      const { data } = await updateUserInfoRequest({ ...agency, userIdNumber: UserId })
      if (data.HasError) {
        setServerError(data.ErrorMessage ?? 'Server Error')
      }
      setSubmitLoading(false)
    } catch (error) {
      setSubmitLoading(false)
      setServerError('Server Error')
    }
  }

  useEffect(() => {
    setAgency({ fullName, agencyName, userEmail, userPhone })
  }, [fullName, agencyName, userEmail, userPhone])

  return (
    <div className='profile-info-container'>
      <div className='profile-info'>
        <div className='profile-avatar-container'>
          <ProfileAvatar />
        </div>
        <div className='profile-info-details'>
          <InputText
            disabled={inputsDisabled}
            inputName='fullName'
            id='fullName'
            label={t('details.fullName')}
            value={agency.fullName ?? ''}
            onChange={onChangeHandler('fullName')}
          />
          <InputText
            disabled={inputsDisabled}
            inputName='agencyName'
            id='agencyName'
            label={t('details.agencyName')}
            value={agency.agencyName ?? ''}
            onChange={onChangeHandler('agencyName')}
          />
          <InputText
            disabled={inputsDisabled}
            inputName='userEmail'
            id='userEmail'
            label={t('details.userEmail')}
            value={agency.userEmail ?? ''}
            onChange={onChangeHandler('userEmail')}
            inputType='email'
          />
          <InputText
            disabled
            inputName='userPhone'
            id='userPhone'
            label={t('details.userPhone')}
            value={formatAsPhoneNumber(agency.userPhone)}
            inputType='phone'
            style={{ direction: 'ltr', textAlign: hebrew() ? 'end' : 'start' }}
            onChange={() => {}}
          />
          <InputText disabled inputName='role' id='role' label={t('details.role')} value={roleName ?? ''} />
          <div className='profile-info-version'>
            <p>
              ver: <span>{getVersion().version}</span>
            </p>
          </div>
          {serverError && <span>{serverError}</span>}
        </div>
        {/* <div className='profile-info-delete'>
            <h6>{t('details.deleteUser')}</h6>
            <p>{t('details.deleteUserRemark')}</p>
            <ButtonText styles={'danger'}>
              <DeleteTwoTone />
              <span>{t('details.deleteUser')}</span>
            </ButtonText>
          </div> */}
      </div>
      <ButtonRounded disabled={submitBtnDisabled()} onClick={submitHandler}>
        {t('details.saveBtn')}
      </ButtonRounded>
    </div>
  )
}

export default ProfileInfo
