export const GET_POLICIES_FILTERS_REQUEST = 'GET_POLICIES_FILTERS_REQUEST'
export const GET_POLICIES_FILTERS_SUCCESS = 'GET_POLICIES_FILTERS_SUCCESS'
export const GET_POLICIES_FILTERS_FAIL = 'GET_POLICIES_FILTERS_FAIL'
export const APPLY_POLICIES_FILTERS_SEARCH = 'APPLY_POLICIES_FILTERS_SEARCH'
export const APPLY_POLICIES_FILTERS_SELECT = 'APPLY_POLICIES_FILTERS_SELECT'
export const APPLY_POLICIES_FILTERS_RANGE = 'APPLY_POLICIES_FILTERS_RANGE'
export const APPLY_POLICIES_FILTERS_DATE = 'APPLY_POLICIES_FILTERS_DATE'
export const POLICIES_FILTERS_RESET = 'POLICIES_FILTERS_RESET'
export const POLICIES_TOGGLE_RANGE_FILTERS_VISIBILITY = 'POLICIES_TOGGLE_RANGE_FILTERS_VISIBILITY'
export const POLICIES_SET_SORT_BY = 'POLICIES_SET_SORT_BY'
