import {
  CUSTOMER_INFO_REQUEST,
  CUSTOMER_INFO_SUCCESS,
  CUSTOMER_INFO_FAIL,
  CUSTOMER_SEARCH_REQUEST,
  CUSTOMER_SEARCH_SUCCESS,
  CUSTOMER_SEARCH_FAIL,
  CUSTOMER_SEARCH_RESET,
  CUSTOMER_GET_GROUP_SUCCESS,
  CUSTOMER_ADD_MEMBER_REQUEST,
  CUSTOMER_ADD_MEMBER_SUCCESS,
  CUSTOMER_ADD_MEMBER_FAIL,
  CUSTOMER_INFO_CLEAR,
  CUSTOMER_GET_GROUP_TYPES_REQUEST,
  CUSTOMER_GET_GROUP_TYPES_SUCCESS,
  CUSTOMER_GET_GROUP_TYPES_FAIL,
  CUSTOMER_ADD_MEMBER_RESET,
  CUSTOMER_CATEGORIES_REQUEST,
  CUSTOMER_CATEGORIES_SUCCESS,
  CUSTOMER_CATEGORIES_FAIL,
  CUSTOMER_AGENTS_REQUEST,
  CUSTOMER_AGENTS_FAIL,
  CUSTOMER_AGENTS_SUCCESS,
  CUSTOMER_TREAT_REQUEST,
  CUSTOMER_TREAT_SUCCESS,
  CUSTOMER_TREAT_FAIL,
  CUSTOMER_IGNORE_REQUEST,
  CUSTOMER_IGNORE_SUCCESS,
  CUSTOMER_IGNORE_FAIL,
  CUSTOMER_TREAT_RESET,
  CUSTOMER_ALL_CATEGORIES_RESET,
  CUSTOMER_GROUPS_REQUEST,
  CUSTOMER_GROUPS_SUCCESS,
  CUSTOMER_GROUPS_FAIL,
} from '../../../types/actionTypes'
import { irregularStatus } from '../../../types/irregularStatusTypes'

const initialState = {
  customerInfo: {},
  customerInfoLoading: true,
  customerInfoError: null,

  usedCategories: [],
  unusedCategories: [],
  categoriesLoading: true,
  categoriesError: null,

  policies: [],
  policiesLoading: true,
  policiesError: null,

  selectedPolicy: null,

  customerGroupMembers: [],

  searchMemberList: [],
  searchMemberListItems: 0,
  searchMemberSuccess: false,
  searchMemberLoading: false,
  searchMemberError: null,
  addMemberLoading: false,
  addMemberSuccess: false,
  addMemberError: null,
  groupTypes: [],
  groupTypesLoading: true,
  groupTypesError: null,
  agents: [],
  agentsLoading: true,
  agentsError: null,
  treatSuccess: false,
  treatLoading: false,
  treatError: null,
  ignoreLoading: true,
  ignoreSuccess: false,
  ignoreError: null,
  customerGroups: [],
  customerGroupsLoading: true,
}

export const customerReducer = (state = initialState, action) => {
  switch (action.type) {
    case CUSTOMER_INFO_REQUEST:
      return {
        ...state,
        customerInfoLoading: true,
        customerInfoError: null,
        customerInfo: {},
        customerGroupMembers: [],
      }
    case CUSTOMER_INFO_SUCCESS:
      return {
        ...state,
        customerInfo: action.payload,
        customerInfoLoading: false,
        customerInfoError: null,
      }
    case CUSTOMER_INFO_FAIL:
      return {
        ...state,
        customerInfoLoading: false,
        customerInfoError: action.payload,
      }
    case CUSTOMER_CATEGORIES_REQUEST:
      return {
        ...state,
        categoriesLoading: true,
        usedCategories: [],
        unusedCategories: [],
        categoriesError: null,
      }
    case CUSTOMER_CATEGORIES_SUCCESS:
      return {
        ...state,
        categoriesLoading: false,
        usedCategories: action.payload.usedCategories,
        unusedCategories: action.payload.unusedCategories,
        categoriesError: null,
      }
    case CUSTOMER_CATEGORIES_FAIL:
    case CUSTOMER_ALL_CATEGORIES_RESET:
      return {
        ...state,
        categoriesLoading: true,
        usedCategories: [],
        unusedCategories: [],
        categoriesError: action.payload,
      }
    case CUSTOMER_ADD_MEMBER_REQUEST:
      return {
        ...state,
        addMemberLoading: true,
        addMemberSuccess: false,
        addMemberError: null,
      }
    case CUSTOMER_ADD_MEMBER_SUCCESS:
      return {
        ...state,
        addMemberLoading: false,
        addMemberSuccess: true,
        addMemberError: null,
      }
    case CUSTOMER_ADD_MEMBER_FAIL:
      return {
        ...state,
        addMemberLoading: false,
        addMemberSuccess: false,
        addMemberError: action.payload,
      }
    case CUSTOMER_ADD_MEMBER_RESET:
      return {
        ...state,
        addMemberLoading: false,
        addMemberSuccess: false,
        addMemberError: null,
      }
    case CUSTOMER_GET_GROUP_TYPES_REQUEST:
      return {
        ...state,
        groupTypesLoading: true,
        groupTypesError: null,
      }
    case CUSTOMER_GET_GROUP_TYPES_SUCCESS:
      return {
        ...state,
        groupTypes: action.payload,
        groupTypesLoading: false,
        groupTypesError: null,
      }
    case CUSTOMER_GET_GROUP_TYPES_FAIL:
      return {
        ...state,
        groupTypesLoading: false,
        groupTypesError: action.payload,
      }
    case CUSTOMER_INFO_CLEAR:
      return {
        ...state,
        customerInfo: {},
        customerInfoLoading: false,
        customerInfoError: null,
      }
    case CUSTOMER_GET_GROUP_SUCCESS:
      return {
        ...state,
        customerGroupMembers: action.payload.filter(item => item.id !== state.customerInfo.id),
      }
    case CUSTOMER_SEARCH_REQUEST:
      return { ...state, searchMemberLoading: true, searchMemberError: null }
    case CUSTOMER_SEARCH_SUCCESS:
      return {
        ...state,
        searchMemberList: action.payload.items,
        searchMemberListItems: action.payload.totalCount,
        searchMemberLoading: false,
        searchMemberError: null,
      }

    case CUSTOMER_SEARCH_FAIL:
      return { ...state, searchMemberLoading: false, searchMemberError: action.payload }
    case CUSTOMER_SEARCH_RESET:
      return {
        ...state,
        searchMemberList: [],
        searchMemberLoading: false,
        searchMemberError: null,
      }
    case CUSTOMER_AGENTS_REQUEST:
      return {
        ...state,
        agentsLoading: true,
        agents: [],
        agentsError: null,
      }
    case CUSTOMER_AGENTS_SUCCESS:
      return {
        ...state,
        agentsLoading: false,
        agents: action.payload,
        agentsError: null,
      }
    case CUSTOMER_AGENTS_FAIL:
      return {
        ...state,
        agentsLoading: false,
        agents: [],
        agentsError: action.payload,
      }
    case CUSTOMER_TREAT_REQUEST:
      return {
        ...state,
        treatLoading: true,
        treatSuccess: false,
        treatError: null,
      }
    case CUSTOMER_TREAT_SUCCESS:
      return {
        ...state,
        treatLoading: false,
        treatSuccess: action.payload.success,
        treatError: null,
        irregularities: state.irregularities.map(irr =>
          irr.id === action.payload.irregularityId && action.payload.success
            ? {
                ...irr,
                treatmentInfo: {
                  agentId: action.payload.agentId,
                  agentName: state.agents.find(a => a.id === action.payload.agentId).name,
                  date: new Date(Date.now()),
                },
              }
            : irr
        ),
      }
    case CUSTOMER_TREAT_FAIL:
      return {
        ...state,
        treatLoading: false,
        treatSuccess: false,
        treatError: action.payload,
      }
    case CUSTOMER_TREAT_RESET:
      return {
        ...state,
        treatSuccess: false,
      }
    case CUSTOMER_IGNORE_REQUEST:
      return {
        ...state,
        ignoreLoading: true,
        ignoreSuccess: false,
        ignoreError: null,
      }
    case CUSTOMER_IGNORE_SUCCESS:
      return {
        ...state,
        ignoreLoading: false,
        ignoreSuccess: action.payload.success,
        ignoreError: null,
        irregularities: action.payload.success
          ? state.irregularities.filter(irr => irr.id !== action.payload.irregularityId)
          : state.irregularities,
        policies: action.payload.success
          ? state.policies.map(p => {
              if (
                p.id === state.selectedPolicy &&
                state.irregularities.filter(irr => irr.id !== action.payload.irregularityId).length === 0
              ) {
                return {
                  ...p,
                  status: irregularStatus.normal,
                }
              }
              return p
            })
          : state.policies,
      }
    case CUSTOMER_IGNORE_FAIL:
      return {
        ...state,
        ignoreLoading: false,
        ignoreSuccess: false,
        ignoreError: action.payload,
      }
    case CUSTOMER_GROUPS_REQUEST:
      return {
        ...state,
        customerGroupsLoading: true,
      }
    case CUSTOMER_GROUPS_SUCCESS:
      return {
        ...state,
        customerGroupsLoading: false,
        customerGroups: action.payload,
      }
    case CUSTOMER_GROUPS_FAIL:
      return {
        ...state,
        customerGroupsLoading: false,
      }
    default:
      return state
  }
}
