import { getItemFromLocalStorage } from '../../../services/localStorageService'
import {
  POLICIES_FILTERS_REQUEST,
  POLICIES_FILTERS_SUCCESS,
  POLICIES_FILTERS_FAIL,
  POLICIES_SIGNALS_FILTER_APPLY,
} from '../../../types/actionTypes'
import {
  APPLY_POLICIES_FILTERS_DATE,
  APPLY_POLICIES_FILTERS_RANGE,
  APPLY_POLICIES_FILTERS_SEARCH,
  APPLY_POLICIES_FILTERS_SELECT,
  GET_POLICIES_FILTERS_FAIL,
  GET_POLICIES_FILTERS_REQUEST,
  GET_POLICIES_FILTERS_SUCCESS,
  POLICIES_FILTERS_RESET,
  POLICIES_SET_SORT_BY,
  POLICIES_TOGGLE_RANGE_FILTERS_VISIBILITY,
} from '../../../types/actionTypesPolicies'
import { agencyType } from '../../../types/agencyTypes'
import { irregularSignal } from '../../../types/irregularSignals'
import { policyStatus } from '../../../types/policyStatusType'
import { sortBy, sortOrder } from '../../../types/sortByTypes'

const sortByCommission =
  +getItemFromLocalStorage('AgencyType') === agencyType.delta ? sortBy.commissionAgency : sortBy.commission

const signalsFilterInit = Object.values(irregularSignal)
  .filter(s => s?.irregularReasons?.length > 0)
  .map(({ order, id, name, nameEn }) => {
    return {
      order,
      id,
      name,
      nameEn,
      checked: false,
    }
  })
  .sort((a, b) => a.order - b.order)

const rangeInit = initVal => ({
  from: initVal,
  to: initVal,
})

const searchFiltersInit = {
  customerName: '',
  customerIdNumber: '',
  policyNumber: '',
  reportName: '',
}

const datesFiltersInit = {
  anyMonthInReportsDatesInit: rangeInit(null),
  firstTimeInReportDatesInit: rangeInit(null),
  irregularityStartDateInit: rangeInit(null),
  policyStartDatesInit: rangeInit(null),
  policyEndDatesInit: rangeInit(null),
  anyMonthInReportsDates: rangeInit(null),
  firstTimeInReportDates: rangeInit(null),
  irregularityStartDate: rangeInit(null),
  policyStartDates: rangeInit(null),
  policyEndDates: rangeInit(null),
}

const selectFiltersInit = {
  companies: [],
  categories: [],
  policyTypes: [],
  agents: [],
  statuses: Object.values(policyStatus),
  irregMonths: [],
  irregsSort: [
    {
      id: 'month',
      name: 'חודש החריגה',
      nameEn: 'month of irregularity',
      selected: true,
    },
    {
      id: 'total',
      name: "מס' חריגות",
      nameEn: 'total irregs',
      selected: false,
    },
  ],
}

const rangeFiltersInit = {
  premiumRange: rangeInit(null),
  commissionRange: rangeInit(null),
  accumulationRange: rangeInit(null),
  commissionRateByPremiumRange: rangeInit(null),
  commissionRateByAccumulationRange: rangeInit(null),
  averageCommissionRateRange: rangeInit(null),
  expectedCommissionRateRange: rangeInit(null),
}

const initialState = {
  searchFilters: searchFiltersInit,
  datesFilters: datesFiltersInit,
  selectFiltersInit,
  selectFilters: selectFiltersInit,
  selectExtraFilters: {},
  mainExtraFilterName: '',
  rangeFilters: rangeFiltersInit,
  showRangeFilters: false,
  filtersLoading: true,
  filtersError: null,
  sort: {
    byElementary: sortByCommission,
    orderElementary: sortOrder.desc,
    byLife: sortByCommission,
    orderLife: sortOrder.desc,
    bySpecial: sortByCommission,
    orderSpecial: sortOrder.desc,
    bySignals: sortByCommission,
    orderSignals: sortOrder.desc,
  },
  companiesFilter: [],
  categoriesFilter: [],
  signalsFilter: signalsFilterInit,
}

export const policiesReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case GET_POLICIES_FILTERS_REQUEST: {
      return {
        ...state,
        filtersLoading: true,
        filtersError: null,
        searchFilters: searchFiltersInit,
        datesFilters: datesFiltersInit,
        selectFilters: selectFiltersInit,
      }
    }
    case GET_POLICIES_FILTERS_SUCCESS: {
      const { dates, select } = payload
      return {
        ...state,
        filtersLoading: false,
        filtersError: null,
        datesFilters: { ...state.datesFilters, ...dates },
        selectFilters: { ...state.selectFilters, ...select },
        selectFiltersInit: { ...state.selectFiltersInit, ...select },
      }
    }
    case GET_POLICIES_FILTERS_FAIL: {
      return {
        ...state,
        filtersLoading: false,
        filtersError: payload,
        searchFilters: searchFiltersInit,
        datesFilters: datesFiltersInit,
        selectFilters: selectFiltersInit,
      }
    }
    case APPLY_POLICIES_FILTERS_SEARCH: {
      const { key, value } = payload
      return { ...state, searchFilters: { ...state.searchFilters, [key]: value } }
    }
    case APPLY_POLICIES_FILTERS_SELECT: {
      const { key: filterName, value: selectedIds } = payload
      const selectFiltersInit = { ...state.selectFiltersInit }
      let mainExtraFilterName = state.mainExtraFilterName
      let filterByCategory = false
      let filterByCompany = false
      let companiesToFilter = []
      let categoriesToFilter = []
      let selectExtraFilters = state.selectExtraFilters
      let selectFilters = state.selectFilters
      if (selectedIds.length === 0) {
        // if main filter resetted (no items selected), reset all extra filters
        if (mainExtraFilterName === filterName) {
          selectExtraFilters = {}
          selectFilters = {
            ...state.selectFilters,
            agents: selectFiltersInit.agents,
            companies: selectFiltersInit.companies,
            categories: selectFiltersInit.categories,
            policyTypes: selectFiltersInit.policyTypes,
          }
          mainExtraFilterName = ''
        }
        // if side filter resetted (no items selected), reset selected filter only
        else {
          selectExtraFilters = { ...state.selectExtraFilters, [filterName]: {} }
          selectFilters = { ...state.selectFilters, [filterName]: selectFiltersInit[filterName] }
        }
      }
      if (selectedIds.length > 0) {
        // assign main filter name for first selected
        if (mainExtraFilterName === '') {
          mainExtraFilterName = filterName
        }
        filterByCompany = filterName === 'agents' || filterName === 'companies' || filterName === 'policyTypes'
        filterByCategory = filterName === 'categories' || filterName === 'policyTypes'
        const selectedEnteties = state.selectFilters[filterName].filter(ent => selectedIds.includes(ent.id))
        if (filterByCompany) {
          companiesToFilter = selectedEnteties.map(ent => ent.companyId ?? ent.id)
        }
        if (filterByCategory) {
          categoriesToFilter = selectedEnteties.map(ent => ent.categoryId ?? ent.id)
        }
        selectExtraFilters = { ...state.selectExtraFilters, [filterName]: { companiesToFilter, categoriesToFilter } }
        companiesToFilter = [
          ...new Set(
            Object.values(selectExtraFilters)
              .filter(f => f.companiesToFilter)
              .map(f => f.companiesToFilter)
              .flat()
          ),
        ]
        categoriesToFilter = [
          ...new Set(
            Object.values(selectExtraFilters)
              .filter(f => f.categoriesToFilter)
              .map(f => f.categoriesToFilter)
              .flat()
          ),
        ]
        // ignore extra filters for these selects
        if (
          mainExtraFilterName !== 'statuses' &&
          mainExtraFilterName !== 'irregMonths' &&
          mainExtraFilterName !== 'irregsSort'
        ) {
          if (mainExtraFilterName !== 'categories') {
            selectFilters.categories = state.selectFilters.categories.filter(
              c => categoriesToFilter.includes(c.id) || c.relativesIds.some(r => companiesToFilter.includes(r))
            )
          }
          if (mainExtraFilterName !== 'agents') {
            selectFilters.agents = state.selectFilters.agents.filter(a => companiesToFilter.includes(a.companyId))
          }
          if (mainExtraFilterName !== 'companies') {
            selectFilters.companies = state.selectFilters.companies.filter(
              c => companiesToFilter.includes(c.id) || c.relativesIds.some(r => categoriesToFilter.includes(r))
            )
          }
          if (mainExtraFilterName !== 'policyTypes') {
            selectFilters.policyTypes = state.selectFilters.policyTypes.filter(
              pt => companiesToFilter.includes(pt.companyId) || categoriesToFilter.includes(pt.categoryId)
            )
          }
        }
      }
      return {
        ...state,
        selectExtraFilters,
        mainExtraFilterName,
        selectFilters: {
          ...state.selectFilters,
          ...selectFilters,
          [filterName]: state.selectFilters[filterName].map(itm => ({
            ...itm,
            selected: selectedIds.includes(itm.id),
          })),
        },
        selectFiltersInit: {
          ...selectFiltersInit,
          [filterName]: selectFiltersInit[filterName].map(itm => ({
            ...itm,
            selected: selectedIds.includes(itm.id),
          })),
        },
      }
    }
    case APPLY_POLICIES_FILTERS_RANGE: {
      const { key, value } = payload
      return {
        ...state,
        rangeFilters: {
          ...state.rangeFilters,
          [key]: value,
        },
      }
    }
    case APPLY_POLICIES_FILTERS_DATE: {
      return {
        ...state,
        datesFilters: {
          ...state.datesFilters,
          ...payload,
        },
      }
    }
    case POLICIES_FILTERS_REQUEST:
      return {
        ...state,
        companiesFilter: [],
        categoriesFilter: [],
      }
    case POLICIES_FILTERS_SUCCESS:
      return {
        ...state,
        companiesFilter: action.payload.companiesFilter,
        categoriesFilter: action.payload.categoriesFilter,
      }
    case POLICIES_FILTERS_FAIL:
      return {
        ...state,
        companiesFilter: [],
        categoriesFilter: [],
      }
    case POLICIES_SIGNALS_FILTER_APPLY: {
      const { signalId, checked } = action.payload
      return {
        ...state,
        signalsFilter: state.signalsFilter
          .map(s => (s.id === signalId ? { ...s, checked } : s))
          .map((item, _, arr) =>
            arr.every(itm => itm.checked) || arr.every(itm => !itm.checked) ? { ...item, checked: false } : item
          ),
      }
    }
    case POLICIES_TOGGLE_RANGE_FILTERS_VISIBILITY:
      return {
        ...state,
        showRangeFilters: !state.showRangeFilters,
      }
    case POLICIES_SET_SORT_BY:
      const { sortBy: sortByToSet, listKey } = action.payload
      const {
        sort: { [`by${listKey}`]: currentSortBy, [`order${listKey}`]: currentSortOrder },
      } = state
      return {
        ...state,
        sort: {
          ...state.sort,
          [`by${listKey}`]: sortByToSet,
          [`order${listKey}`]:
            sortByToSet === currentSortBy
              ? currentSortOrder !== sortOrder.asc
                ? sortOrder.asc
                : sortOrder.desc
              : currentSortOrder,
        },
      }
    case POLICIES_FILTERS_RESET: {
      const selectFiltersInit = Object.fromEntries(
        Object.entries(state.selectFiltersInit).map(([filterName, values]) => {
          return [filterName, values.map(v => ({ ...v, selected: false }))]
        })
      )
      return {
        ...state,
        searchFilters: searchFiltersInit,
        datesFilters: datesFiltersInit,
        selectFiltersInit,
        selectFilters: selectFiltersInit,
        selectExtraFilters: {},
        mainExtraFilterName: '',
        rangeFilters: rangeFiltersInit,
      }
    }
    default:
      return state
  }
}
