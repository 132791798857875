import { useEffect, useRef, useState } from 'react'
import { hebrew } from '../../../../i18n'
import { formatAsPercent } from '../../../../utils/formatAs'
import ModalWithSelection from '../../../../components/Common/ModalWithSelection/ModalWithSelection'
import { useSelector } from 'react-redux'
import PolicyListItemCard from '../PolicyListItemCard/PolicyListItemCard'
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material'
import { cn } from '../../../../utils/stylesUtils'
import LoadingOverlay from '../../../../components/Common/LoadingOverlay/LoadingOverlay'
import { floatingNumbersOnly } from '../../../../services/inputs'
import { columnsConfigPage, columnType } from '../../../../services/columnsConfig/columnsConfigTypes'
import { getAvailableColumnsToShow } from '../../../../services/columnsConfig/columnsConfigHelpers'
import './PolicyTypeListItem.scss'
import useOutsideClick from '../../../../hooks/useOutsideClick'

const PolicyTypeListItem = ({ policyType, updatePolicyType, styles }) => {
  const {
    name,
    companyId,
    companyName,
    companyNameEn,
    categoryId,
    categoryName,
    categoryNameEn,
    reportName,
    averageCommissionRate,
    expectedCommissionRate,
    loading,
    categoryChanged,
    commissionRateChanged,
  } = policyType

  const { categories } = useSelector(({ shared }) => shared)
  const policyTypeCategory = categories.find(c => c.id === categoryId)
  const availableCategories = categories
    .filter(c => c.fieldId === policyTypeCategory.fieldId)
    .map(c => ({ ...c, value: c[`${hebrew() ? 'name' : 'englishName'}`] }))

  const updateCategoryHandler = value => {
    const newCategeory = categories.find(c => c.id === value)
    updatePolicyType({
      ...policyType,
      categoryName: newCategeory.name,
      categoryNameEn: newCategeory.englishName,
      categoryId: newCategeory.id,
    })
    setShowCategoriesSelector(false)
  }

  const inputRef = useRef()
  const commissionRateInitValue = formatAsPercent(expectedCommissionRate, {
    dashesForZero: false,
  })
  const [inputValue, setInputValue] = useState(commissionRateInitValue)
  const [resetInputValue, setResetInputValue] = useState(false)

  const changeInputValue = ({ target: { value } }) => {
    const charToExclude = '%'
    const rawValue = value.replace(charToExclude, '')
    if (rawValue === '' || floatingNumbersOnly(rawValue)) {
      setInputValue(rawValue + charToExclude)
    }
  }

  const updateComissionHandler = () => {
    if (inputValue.length > 1 && inputValue !== commissionRateInitValue && !resetInputValue) {
      const charToExclude = '%'
      const expectedCommissionRate = Number(inputValue.replace(charToExclude, ''))
      updatePolicyType({
        ...policyType,
        expectedCommissionRate,
      })
    } else setInputValue(commissionRateInitValue)
  }

  const [showCategoriesSelector, setShowCategoriesSelector] = useState(false)
  const categoriesCellRef = useRef()
  const selectorRef = useRef()
  useOutsideClick(selectorRef, () => {
    if (showCategoriesSelector) {
      setShowCategoriesSelector(false)
    }
  })

  let positionTop = false

  if (showCategoriesSelector) {
    const windowHeight = window.innerHeight
    const widndowBottomPadding = 50
    const cellBottom = categoriesCellRef?.current?.getBoundingClientRect().bottom
    positionTop = windowHeight - widndowBottomPadding - cellBottom < 300
  }

  const availableColumnsNames = getAvailableColumnsToShow(columnsConfigPage.coverage).map(c => c.columnName)

  useEffect(() => {
    resetInputValue && inputRef.current.blur()
  }, [resetInputValue])

  return (
    <PolicyListItemCard classes='policy-types-list-item policy-types-list-grid' styles={styles}>
      {loading && <LoadingOverlay />}
      {availableColumnsNames.includes(columnType.policyTypeName) && <div>{name ?? '--'}</div>}
      {availableColumnsNames.includes(columnType.company) && (
        <div>
          <div className='company-info'>
            <img src={`./assets/companies-logos-light/${companyId}.png`} alt={companyId} />
            <p>{hebrew() ? companyName : companyNameEn}</p>
          </div>
        </div>
      )}
      {availableColumnsNames.includes(columnType.category) && (
        <div ref={categoriesCellRef}>
          <div className='category-selector' ref={selectorRef}>
            <button
              className={cn('item-input', { error: categoryChanged })}
              onClick={() => setShowCategoriesSelector(!showCategoriesSelector)}
            >
              <span>{hebrew() ? categoryName : categoryNameEn}</span>
              {showCategoriesSelector ? <ArrowDropUp /> : <ArrowDropDown />}
            </button>
            {showCategoriesSelector && (
              <div className={cn('modal-selection-wrapper', { 'position-top': positionTop })}>
                <ModalWithSelection
                  showSearch
                  items={availableCategories}
                  closeModal={() => setShowCategoriesSelector(false)}
                  currentItemId={categoryId}
                  onClickHandler={updateCategoryHandler}
                />
              </div>
            )}
          </div>
        </div>
      )}
      {availableColumnsNames.includes(columnType.reportName) && <div>{reportName ?? '--'}</div>}
      {availableColumnsNames.includes(columnType.averageCommissionRate) && (
        <div className='financial-value-cell'>{formatAsPercent(averageCommissionRate, { dashesForZero: false })}</div>
      )}
      {availableColumnsNames.includes(columnType.expectedCommissionRate) && (
        <div className='financial-value-cell'>
          <div className={cn('item-input', { error: commissionRateChanged })}>
            <input
              ref={inputRef}
              value={inputValue}
              onChange={changeInputValue}
              onFocus={() => {
                setResetInputValue(false)
                setInputValue('%')
              }}
              onBlur={updateComissionHandler}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  inputRef.current.blur()
                }
                if (e.key === 'Escape') {
                  setResetInputValue(true)
                }
              }}
            />
          </div>
          <div></div>
        </div>
      )}
      <div></div>
    </PolicyListItemCard>
  )
}

export default PolicyTypeListItem
