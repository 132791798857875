import classNames from 'classnames'
import { Fragment, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import ModalWithNote from '../../../../components/Common/ModalWithNote/ModalWithNote'
import { CUSTOMER_UNCHECK_ALL, POLICY_TREAT_RESET } from '../../../../types/actionTypes'
import { customerTaskType } from '../../../../types/customerTaskTypes'
import AddUserModal from '../../../customer/components/AddUserModal/AddUserModal'
import CustomerActionModal from '../../../customer/components/CustomerActionModal/CustomerActionModal'
import { getAgents, getCategories } from '../../../shared/actions/sharedActions'
import { sendForTreatBatch } from '../../actions/policyActions'
import AddCustomerGroupModal from '../../../../components/AddCustomerGroupModal/AddCustomerGroupModal'
import { getCustomersTotalDashboard } from '../../../customers/actions/customersActions'
import './PoliciesActions.scss'

const PoliciesActions = ({ policies, customers = [], tasks = [], closeModal = () => {} }) => {
  const { t, i18n } = useTranslation('customer')
  const dispatch = useDispatch()
  const treatSuccess = useSelector(state => state.policy).treatSuccess
  const treatLoading = useSelector(state => state.policy).treatLoading

  const agents = useSelector(state => state.shared).agents
  const agentsLoading = useSelector(state => state.shared).agentsLoading

  const [showAddTreatUserModal, setShowAddTreatUserModal] = useState(false)
  const [showTreatNoteModal, setShowTreatNoteModal] = useState(false)
  const [showTreatActionModal, setShowTreatActionModal] = useState(false)
  const [user, setUser] = useState({ currAgent: 2 })
  const [showAddGroupModal, setShowAddGroupModal] = useState(false)

  const actionsForCustomer = customers.length > 0

  const itemsLength = actionsForCustomer ? customers.length : tasks.length

  const actions = [
    {
      icon: './assets/button-icons/send-light/send.png',
      text: 'העבר ל',
      textEn: 'Send to',
      action: () => {
        setUser({})
        setShowAddTreatUserModal(true)
      },
    },
  ]

  customers.length > 1 &&
    actions.push({
      type: customerTaskType.groupAffiliation,
      icon: './assets/plus-icon/Add.png',
      text: 'צור קבוצה',
      textEn: 'Create a Group',
      action: () => setShowAddGroupModal(true),
    })

  const addUser = newUser => {
    setUser({ ...user, ...newUser })
    setShowAddTreatUserModal(false)
    if (actionsForCustomer) {
      setShowTreatActionModal(true)
    } else {
      setShowTreatNoteModal(true)
    }
  }

  const selectAction = actionId => {
    setUser({ ...user, actionId })
  }

  const sendForTreatHandler = note => {
    const agentId = user.id
    const type = user.actionId
    const treatDtos = actionsForCustomer
      ? customers.map(c => {
          return {
            agentId: agentId,
            customerId: c.id,
            type,
          }
        })
      : tasks.map(t => ({
          agentId: agentId,
          customerId: t.customerId,
          taskId: t.id,
          type,
        }))
    dispatch(sendForTreatBatch(treatDtos))
  }

  useEffect(() => {
    if (showAddTreatUserModal) {
      dispatch(getAgents())
    }
  }, [showAddTreatUserModal, dispatch])

  useEffect(() => {
    dispatch(getCategories())
  }, [dispatch])

  return (
    <>
      <div className='policies-actions-panel-container'>
        <div className='policies-actions-panel'>
          <div onClick={closeModal} className='actions-close-icon'>
            <img src='./assets/close-icon-sm/Close.png' alt='cls' className='img-icon' />
          </div>
          <div className='actions-content'>
            <div className='policies-count'>{itemsLength}</div>
            <div className='actions-container'>
              {actions.map((ac, i) => (
                <Fragment key={ac.textEn + i}>
                  <div
                    className={classNames('policy-action', {
                      disabled: ac.disabled,
                    })}
                    onClick={ac.action}
                  >
                    <div className='action-ico'>
                      <img src={ac.icon} alt={ac.textEn} />
                    </div>
                    <div className='action-text'>{i18n.language === 'he' ? ac.text : ac.textEn}</div>
                  </div>
                </Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>
      {showAddTreatUserModal && (
        <AddUserModal
          users={agents}
          loading={agentsLoading}
          closeModal={() => setShowAddTreatUserModal(false)}
          closeOnOutsideClick={false}
          onAddUser={addUser}
          currentUser={user?.currAgent}
          askConfirm={user?.currAgent}
        />
      )}
      {showTreatNoteModal && (
        <ModalWithNote
          closeModal={() => setShowTreatNoteModal(false)}
          closeOnOutsideClick={false}
          title={t('treatNoteModal.modalTitle')}
          subtitle={t('treatNoteModal.modalSubtitle')}
          label={t('treatNoteModal.inputLabel')}
          btnCaption={t('treatNoteModal.btnTitle')}
          back={() => setShowAddTreatUserModal(true)}
          onAddNote={sendForTreatHandler}
        />
      )}
      {showTreatActionModal && (
        <CustomerActionModal
          closeModal={() => setShowTreatActionModal(false)}
          closeOnOutsideClick={false}
          onSelectAction={selectAction}
          onSubmit={sendForTreatHandler}
          submitSuccess={treatSuccess}
          resetSuccess={() => {
            dispatch({ type: POLICY_TREAT_RESET })
            dispatch({ type: CUSTOMER_UNCHECK_ALL })
          }}
          loading={treatLoading}
          currentAction={user?.actionId}
          back={() => setShowAddTreatUserModal(true)}
          agentName={user?.name}
        />
      )}{' '}
      {showAddGroupModal && (
        <AddCustomerGroupModal
          groupCustomers={customers}
          closeModal={() => setShowAddGroupModal(false)}
          onAddSuccess={() => {
            dispatch({ type: CUSTOMER_UNCHECK_ALL })
            dispatch(getCustomersTotalDashboard())
          }}
        />
      )}
    </>
  )
}

export default PoliciesActions
