import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { hebrew } from '../../../../i18n'
import { APPLY_POLICIES_FILTERS_SELECT, POLICIES_FILTERS_RESET } from '../../../../types/actionTypesPolicies'
import { agencyPoliciesSubroute } from '../../../../environment/urls'
import { useLocation } from 'react-router'
import './SelectedFilters.scss'

const SelectedFilters = () => {
  const { t } = useTranslation('policies')
  const { pathname } = useLocation()
  const signalsRoute = pathname.includes(agencyPoliciesSubroute.signals)
  const dispatch = useDispatch()
  const [selectedCompanies, setSelectedCompanies] = useState([])
  const [selectedCategories, setSelectedCategories] = useState([])
  const [selectedAgents, setSelectedAgents] = useState([])
  const [selectedStatuses, setSelectedStatuses] = useState([])
  const [selectedPolicyTypes, setSelectedPolicyTypes] = useState([])
  const {
    selectFilters: { companies, categories, agents, statuses, irregMonths, policyTypes },
  } = useSelector(({ policies }) => policies)
  const checkSelectFiltersApplied = filters => filters.some(f => f?.some(el => el.selected === true))
  const hasSelectFiltersApplied = checkSelectFiltersApplied([
    companies,
    categories,
    agents,
    statuses,
    irregMonths,
    policyTypes,
  ])

  const applyFilter = (value, key, type) => {
    dispatch({
      type,
      payload: {
        key,
        value,
      },
    })
  }

  useEffect(() => {
    setSelectedCompanies(companies.filter(c => c.selected === true))
    setSelectedCategories(categories.filter(c => c.selected === true))
    setSelectedAgents(agents.filter(a => a.selected === true))
    setSelectedStatuses(statuses.filter(s => s.selected === true))
    setSelectedPolicyTypes(policyTypes.filter(pt => pt.selected === true))
  }, [companies, categories, agents, statuses, policyTypes])

  const clearFilters = () => dispatch({ type: POLICIES_FILTERS_RESET })

  const selectedCompaniesFilterHandler = company => {
    var companyItem = companies.find(c => c.id === company.id)
    companyItem.selected = !company.selected
    const selectedItems = companies.filter(c => c.selected === true).map(c => c.id)
    applyFilter(selectedItems, 'companies', APPLY_POLICIES_FILTERS_SELECT)
  }

  const selectedCategoriesFilterHandler = category => {
    var categoryItem = categories.find(c => c.id === category.id)
    categoryItem.selected = !category.selected
    const selectedItems = categories.filter(c => c.selected === true).map(c => c.id)
    applyFilter(selectedItems, 'categories', APPLY_POLICIES_FILTERS_SELECT)
  }
  const selectedAgentsFilterHandler = agent => {
    var agentItem = agents.find(a => a.id === agent.id)
    agentItem.selected = !agent.selected
    const selectedItems = agents.filter(a => a.selected === true).map(c => c.id)
    applyFilter(selectedItems, 'agents', APPLY_POLICIES_FILTERS_SELECT)
  }
  const selectedStatusesFilterHandler = status => {
    var statusItem = statuses.find(a => a.id === status.id)
    statusItem.selected = !status.selected
    const selectedItems = statuses.filter(s => s.selected === true).map(c => c.id)
    applyFilter(selectedItems, 'statuses', APPLY_POLICIES_FILTERS_SELECT)
  }

  const selectedPolicyTypesFilterHandler = policyType => {
    var policyTypeItem = policyTypes.find(pt => pt.id === policyType.id)
    policyTypeItem.selected = !policyType.selected
    const selectedItems = policyTypes.filter(c => c.selected === true).map(c => c.id)
    applyFilter(selectedItems, 'policyTypes', APPLY_POLICIES_FILTERS_SELECT)
  }

  return (
    (hasSelectFiltersApplied || signalsRoute) && (
      <div className={`policies-selected-filters-content ${hebrew() ? 'selected-filters-position-hebrew' : ''} `}>
        <div className='policies-selected-filters-items'>
          {selectedCompanies.length > 0 &&
            selectedCompanies.map(c => (
              <div className='policies-selected-filters-button' key={c.id}>
                <button onClick={() => selectedCompaniesFilterHandler(c)}>
                  <img src='./assets/close-label-icon/close-label-icon.png' className='img-icon' alt='clse' />
                </button>
                <div className='label-filter-value'>{hebrew() ? c.name : c.nameEn}</div>
              </div>
            ))}

          {selectedCategories.length > 0 &&
            selectedCategories.map(c => (
              <div className='policies-selected-filters-button' key={c.id}>
                <button onClick={() => selectedCategoriesFilterHandler(c)}>
                  <img src='./assets/close-label-icon/close-label-icon.png' className='img-icon' alt='clse' />
                </button>
                <div className='label-filter-value'>{hebrew() ? c.name : c.nameEn}</div>
              </div>
            ))}
          {selectedAgents.length > 0 &&
            selectedAgents.map(a => (
              <div className='policies-selected-filters-button' key={a.id}>
                <button onClick={() => selectedAgentsFilterHandler(a)}>
                  <img src='./assets/close-label-icon/close-label-icon.png' className='img-icon' alt='clse' />
                </button>
                <div className='label-filter-value'>{hebrew() ? a.name : a.nameEn}</div>
              </div>
            ))}
          {selectedStatuses.length > 0 &&
            selectedStatuses.map(s => (
              <div className='policies-selected-filters-button' key={s.id}>
                <button onClick={() => selectedStatusesFilterHandler(s)}>
                  <img src='./assets/close-label-icon/close-label-icon.png' className='img-icon' alt='clse' />
                </button>
                <div className='label-filter-value'>{hebrew() ? s.name : s.nameEn}</div>
              </div>
            ))}
          {selectedPolicyTypes.length > 0 &&
            selectedPolicyTypes.map(pt => (
              <div className='policies-selected-filters-button' key={pt.id}>
                <button onClick={() => selectedPolicyTypesFilterHandler(pt)}>
                  <img src='./assets/close-label-icon/close-label-icon.png' className='img-icon' alt='clse' />
                </button>
                <div className='label-filter-value'>{hebrew() ? pt.name : pt.nameEn}</div>
              </div>
            ))}
          {hasSelectFiltersApplied && (
            <button onClick={clearFilters} className={'policies-selected-filters-button clear-button'}>
              {t('clearFilters')}
            </button>
          )}
        </div>
      </div>
    )
  )
}

export default SelectedFilters
