import { useEffect } from 'react'
import { useParams } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'
import CustomerInfoContainer from '../../features/customer/components/CustomerContainers/CustomerInfoContainer'
import { getCustomerInfo } from '../../features/customer/actions/customerActions'
import { getCategories } from '../../features/shared/actions/sharedActions'
import { CUSTOMER_INFO_CLEAR, POLICY_RESET } from '../../types/actionTypes'
import { selectPolicy } from '../../features/policy/actions/policyActions'
import { isMobile } from 'react-device-detect'
import NoMobileLayout from '../../components/NoMobileLayout/NoMobileLayout'
import AgentGroupsBanner from '../../components/AgentGroupsBanner/AgentGroupsBanner'
import CustomerTotals from '../../features/customer/components/CustomerTotals/CustomerTotals'
import CustomerMainContainer from '../../features/customer/components/CustomerContainers/CustomerMainContainer'
import { getPoliciesFilters } from '../../features/policies/actions/policiesActions'
import BackToBigAgency from '../../features/shared/components/BackToBigAgency/BackToBigAgency'
import { familyGroupMode } from '../../features/shared/reducers/sharedSelectors'
import { SHARED_SET_FAMILY_GROUPS_TYPE } from '../../types/actionTypesShared'
import { familyGroupType } from '../../types/familyGroupTypes'
import PolicyCard from '../../features/policies/components/PolicyCard/PolicyCard'
import './CustomerScreen.scss'

const CustomerScreen = () => {
  const dispatch = useDispatch()
  const { id } = useParams()

  const customerInfo = useSelector(state => state.customer).customerInfo
  const customerInfoLoading = useSelector(state => state.customer).customerInfoLoading
  const customerGroupMembers = useSelector(state => state.customer).customerGroupMembers
  const selectedPolicy = useSelector(state => state.policy).selectedPolicy
  const policy = useSelector(state => state.customer).policies.find(policy => policy.id === selectedPolicy)
  const { selectedGroupsIds } = useSelector(({ shared }) => shared)

  const closeSidebar = () => {
    dispatch(selectPolicy())
  }

  useEffect(() => {
    if (id) {
      dispatch(getCustomerInfo(id))
    }
  }, [dispatch, id, selectedGroupsIds])

  useEffect(() => {
    const { id = null, familyGroupId } = customerInfo ?? {}
    if (id && !familyGroupId && familyGroupMode()) {
      dispatch({ type: SHARED_SET_FAMILY_GROUPS_TYPE, payload: familyGroupType.standalone })
    }
  }, [customerInfo, dispatch])

  useEffect(() => {
    dispatch(getCategories())
    dispatch(getPoliciesFilters())
    return () => {
      dispatch({ type: CUSTOMER_INFO_CLEAR })
      dispatch({ type: POLICY_RESET })
    }
  }, [dispatch])

  if (isMobile) {
    return <NoMobileLayout pageName={customerInfo.name} />
  }

  return (
    <div className='customer-screen'>
      <div className='customer-screen-banners'>
        <BackToBigAgency />
        <AgentGroupsBanner />
      </div>
      <CustomerInfoContainer
        customerInfo={customerInfo}
        customerInfoLoading={customerInfoLoading}
        customerGroupMembers={customerGroupMembers}
      />
      <div>
        <CustomerTotals id={id} />
        <CustomerMainContainer />
      </div>
      {policy && <PolicyCard closeDetails={closeSidebar} id={policy.id} />}
    </div>
  )
}

export default CustomerScreen
