import { DeleteOutline } from '@mui/icons-material'
import { agencyRoutes } from '../../../../environment/urls'
import { GppGoodOutlined } from '@mui/icons-material'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { SHARED_SET_FAMILY_GROUPS_TYPE_STANDALONE } from '../../../../types/actionTypesShared'
import './CustomerGroupsMemberItem.scss'

const CustomerGroupsMemberItem = ({ customer, removeCustomer }) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const clickHandler = e => {
    e.preventDefault()
    dispatch({ type: SHARED_SET_FAMILY_GROUPS_TYPE_STANDALONE })
    history.push(`/${agencyRoutes.customers}/${customer.id}`)
  }

  return (
    <div className='customer-groups-list-members-item'>
      <div className='customer-groups-list-members-item-info'>
        {customer.isMainFamilyGroupMember && <GppGoodOutlined style={{ color: 'var(--orange-client)' }} />}
        <div>
          <p>{customer.name}</p>
          <p>{customer.unHashedIdNumber}</p>
        </div>
      </div>
      <div className='customer-groups-list-members-item-actions'>
        <button className='customer-groups-list-members-item-action-btn' onClick={removeCustomer}>
          <DeleteOutline />
        </button>
        <a
          href={`#/${agencyRoutes.customers}/${customer.id}`}
          onClick={clickHandler}
          className='customer-groups-list-members-item-action-btn'
        >
          <img src='./assets/export/Export@1.1x.png' alt='edit'></img>
        </a>
      </div>
    </div>
  )
}

export default CustomerGroupsMemberItem
