import versionInfo from './../version.json'

export const getVersion = () => versionInfo

export const logVersion = () => {
  const verConsoleStyle = 'font-size: 12px; font-weight: bold; color: gray;'
  console.log('Version: %c' + versionInfo.version, verConsoleStyle)
  console.log('Build:\t %c' + new Date(versionInfo.date).toLocaleString(), verConsoleStyle)
  console.log('SHA:\t %c' + versionInfo.sha, verConsoleStyle)
}
