import classNames from 'classnames'
import { useState, useEffect, useRef } from 'react'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import BottomSheetBase from '../../../../components/Common/BottomSheetBase/BottomSheetBase'
import CheckMark from '../../../../components/Common/Marks/CheckMark/CheckMark'
import { hebrew } from '../../../../i18n'
import { WORKSTATION_FILTERS_CLEAR } from '../../../../types/actionTypes'
import { applyTasksFilter } from '../../actions/workstationActions'
import WorkstationButtonMobile from '../WorkstationButtonMobile/WorkstationButtonMobile'
import './WorkstationFilter.scss'

const WorkstationFilter = () => {
  const { filters } = useSelector(({ workstation }) => workstation)
  const dispatch = useDispatch()
  const [showFilters, setShowFilters] = useState(false)
  const refFiltersMenu = useRef(null)
  const { t } = useTranslation('workstation')
  const applyFilterHandler = (filterId, valueId) => {
    dispatch(applyTasksFilter(filterId, valueId))
  }

  const getFilterName = ({ name, nameEn }) => (hebrew() ? name : nameEn)

  const appliedFiltersLength = Object.values(filters)
    .flatMap(({ values }) => values)
    .filter(v => v.checked).length

  const getFilterAplliedValues = ({ values }) => {
    const appliedValuesLength = values.filter(v => v.checked).length
    let result = <span></span>
    if (appliedValuesLength > 0) result = <span>{`(${appliedValuesLength})`}</span>
    return result
  }

  const closeMenuHandler = event => {
    if (event.key === 'Escape' || (refFiltersMenu.current && !refFiltersMenu.current.contains(event.target))) {
      setShowFilters(false)
    }
  }

  const renderList = () => (
    <div className='workstation-filter-list'>
      <ul>
        {Object.values(filters).map(filter => (
          <li key={filter.id}>
            <div className='workstation-filter-list-item'>
              <div className='workstation-filters-list-item-text'>
                {getFilterName(filter)} {getFilterAplliedValues(filter)}
              </div>
              <div className='workstation-filters-list-item-ico mobile-hide'>
                <img src='./assets/dropdown-icon-light-left/left.png' alt='nxt' />
              </div>
            </div>
            <div className='workstation-filter-list'>
              <ul>
                {filter.values.map(value => (
                  <li key={value.id} onClick={() => applyFilterHandler(filter.id, value.id)}>
                    <div className={classNames('workstation-filter-list-item', { selected: value.checked })}>
                      <div className='workstation-filters-list-item-text'>{getFilterName(value)}</div>
                      {value.checked && (
                        <div className='workstation-filters-list-item-ico'>
                          {isMobile ? (
                            <img src='./assets/close-icon-sm-green/Close.png' alt='unchk' />
                          ) : (
                            <CheckMark green />
                          )}
                        </div>
                      )}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )

  useEffect(() => {
    if (showFilters && !isMobile) {
      document.addEventListener('keydown', closeMenuHandler, true)
      document.addEventListener('click', closeMenuHandler, true)
    }
    return () => {
      document.removeEventListener('keydown', closeMenuHandler, true)
      document.removeEventListener('click', closeMenuHandler, true)
    }
  }, [showFilters])

  return (
    <div className='workstation-filter-container' ref={refFiltersMenu}>
      <button
        className={classNames('workstation-filter-button', {
          open: showFilters,
          'has-filters': appliedFiltersLength > 0,
        })}
        onClick={() => setShowFilters(!showFilters)}
        disabled={filters.length === 0}
      >
        <div className='workstation-filter-button-content'>
          <div className='workstation-filter-button-icon'>
            <img src='./assets/filter/Filter.png' alt='filters' />
          </div>
          <span className='workstation-filter-button-name mobile-hide'>{t('filters')}</span>
          <span className={classNames('workstation-filter-button-chip', { hidden: appliedFiltersLength === 0 })}>
            {appliedFiltersLength}
          </span>
        </div>
        <div className='workstation-filter-button-icon'>
          <img src='./assets/arrow-dropdown-down/arrow_drop_down_24px.png' alt='open' />
        </div>
      </button>
      {showFilters && renderList()}
      {isMobile && (
        <BottomSheetBase
          open={showFilters}
          sheetTitle={t('filters')}
          closeSheet={() => setShowFilters(false)}
          closeOnOutsideClick={true}
        >
          {renderList()}
          <div className='workstation-filter-bottomsheet-footer'>
            <WorkstationButtonMobile
              caption={t('clearFilters')}
              classes={'secondary'}
              onClick={() => {
                dispatch({ type: WORKSTATION_FILTERS_CLEAR })
                setShowFilters(false)
              }}
            />
            <WorkstationButtonMobile
              caption={t('save')}
              onClick={() => {
                setShowFilters(false)
              }}
            />
          </div>
        </BottomSheetBase>
      )}
    </div>
  )
}

export default WorkstationFilter
