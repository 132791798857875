import $api from '..'
import { urlCustomerGroups } from '../../environment/urls'

export const exportCustomersGroupRequest = async id =>
  await $api
    .get(`${urlCustomerGroups()}export-customers-group/${id}`, { responseType: 'blob' })
    .then(response => {
      const blob = response.data
      let filename = response.headers['content-disposition']
        .split(';')
        .find(n => n.includes('filename='))
        .replace('filename=', '')
        .replaceAll('"', '')
        .trim()
      const link = document.createElement('a')
      const url = URL.createObjectURL(blob)
      link.href = url
      link.download = filename
      link.click()
      link.remove()
    })
    .catch(response => {
      console.error('Could not Export the Customer group from the backend.', response)
    })
